export interface UserBecomeSellerInterface {
  name: string;
  phone: {
    phone: string;
  }[];
  code: {
    phone: string;
  }[];
  viber: boolean[];
  telegram: boolean[];
  whatsapp: boolean[];
  tin: string;
  type: string | null;
  locality_id: number | null;
  files: any[];
  email: string;
}

export interface SellerInterface {
  documents: SellerDocumentInterface[];
  email: string;
  id: number;
  name: string;
  phones: SellerPhonesInterface[];
  status: SellerStatusEnum;
}

export interface SellerDocumentInterface {
  created_at: string;
  id: number;
  mime_type: string;
  name: string;
  size: number;
  url: string;
}

export interface SellerPhonesInterface {
  code: string;
  created_at: string;
  id: number;
  number: string;
  seller_id: number;
  updated_at: string;
  viber: boolean;
  telegram: boolean;
  whatsapp: boolean;
}
export enum SellerStatusEnum {
  IN_PROGRESS = 0,
  APPROVED = 1,
  REJECTED = 2,
}
